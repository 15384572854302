import { __decorate } from "tslib";
import $ from 'jquery';
import { throttle } from 'helpful-decorators';
import { enterTransition, leaveTransition } from '../utils/transition';
import { getCurrentBreakpoint } from '../utils/breakpoint';
import { Breakpoint } from '../types/breakpoint';
import GaPromotionTracking from '../core/components/ga-promotion-tracking';
export default class Navbar {
    static $navbar = $('.js__navbar');
    static $navbarWrapper = $('.js__navbar-wrapper');
    static $backdrop = $('.js__navbar-backdrop');
    static $topNavbarCollapse = $('.js__navbar-collapse-first');
    static $navbarOpen = $('.js__navbar-open');
    static $navbarClose = $('.js__navbar-close');
    static $navbarBack = $('.js__navbar-back');
    static $navbarItem = $('.js__navbar-item');
    static init() {
        this.bindEvents();
        this.setViewportHeightUnit();
    }
    static getNavbarHeight() {
        return this.$navbar.outerHeight();
    }
    static getNavbarComputedHeight() {
        return this.$navbarWrapper.outerHeight();
    }
    static openNavbar(transition = true) {
        this.$topNavbarCollapse.addClass('show');
        this.showBackdrop();
        requestAnimationFrame(() => {
            enterTransition(this.$topNavbarCollapse, this.$topNavbarCollapse, transition);
        });
    }
    static async closeNavbar(transition = true) {
        const $activeNavItem = this.$topNavbarCollapse.parent().find('.active').first();
        if (this.isDesktopNavbar() && transition) {
            return this.closeNavItem($activeNavItem);
        }
        await leaveTransition(this.$topNavbarCollapse, this.$topNavbarCollapse, transition);
        this.$topNavbarCollapse.removeClass('show');
        await this.hideBackdrop();
        if ($activeNavItem.length) {
            await this.closeNavItem($activeNavItem, false);
        }
    }
    static async openNavItem($navItem) {
        const $activeSibling = $navItem.siblings('.active');
        const $menu = $navItem.children('.navbar-collapse');
        const isSecondLevel = $menu.hasClass('navbar-collapse-second');
        const isDesktopNavbar = this.isDesktopNavbar();
        if ($activeSibling.length) {
            this.closeNavItem($activeSibling, isSecondLevel && isDesktopNavbar);
        }
        $navItem.addClass('active');
        $menu.addClass('show');
        GaPromotionTracking.trackViewInElement($menu);
        if (isSecondLevel && isDesktopNavbar) {
            this.openNavbar(false);
        }
        if (!isSecondLevel) {
            const $menuBody = $menu.children('.navbar-collapse-body');
            const $parentMenuBody = $menu.closest('.navbar-collapse-body');
            // Expands parent menu to be at least height of it's children on desktop.
            $parentMenuBody.css('min-height', `${$menuBody.outerHeight()}px`);
        }
        requestAnimationFrame(() => {
            // Double RAF to properly handle nested transitions.
            requestAnimationFrame(() => {
                enterTransition($menu, isSecondLevel && isDesktopNavbar ? $menu : undefined);
            });
        });
    }
    static async closeNavItem($navItem, transition = true) {
        const $activeChild = $navItem.find('.active').first();
        if ($activeChild.length) {
            this.closeNavItem($activeChild, transition);
        }
        const $menu = $navItem.children('.navbar-collapse');
        const isSecondLevel = $menu.hasClass('navbar-collapse-second');
        const isDesktopNavbar = this.isDesktopNavbar();
        if (!isSecondLevel) {
            const $parentMenuBody = $menu.closest('.navbar-collapse-body');
            $parentMenuBody.css('min-height', '');
        }
        await leaveTransition($menu, isSecondLevel && isDesktopNavbar ? $menu : undefined, transition);
        // Reset scroll position on desktop and mobile
        $menu[0].scrollTop = 0;
        $menu.children('.navbar-collapse-body')[0].scrollTop = 0;
        $navItem.removeClass('active');
        $menu.removeClass('show');
        if (transition && isSecondLevel && isDesktopNavbar && this.$topNavbarCollapse.find('.active').length === 0) {
            await this.closeNavbar(false);
        }
    }
    static toggleNavItem($navItem) {
        if ($navItem.hasClass('active')) {
            this.closeNavItem($navItem);
        }
        else {
            this.openNavItem($navItem);
        }
    }
    static bindEvents() {
        $('.js__navbar-link-toggle').on('click', event => {
            event.preventDefault();
            const $target = $(event.currentTarget);
            const $navItem = $target.parent();
            this.toggleNavItem($navItem);
        });
        this.$navbarOpen.on('click', () => {
            this.openNavbar();
        });
        this.$navbarClose.add(this.$backdrop).on('click', () => {
            this.closeNavbar();
        });
        $(document).on('keyup', event => {
            if (event.key === 'Escape' && $(document.body).hasClass('navbar-open')) {
                this.closeNavbar();
            }
        });
        this.$navbarBack.on('click', event => {
            const $target = $(event.currentTarget);
            const $parent = $target.closest('.active');
            this.closeNavItem($parent);
        });
        this.$navbarItem
            .on('mouseenter', event => {
            const $target = $(event.currentTarget);
            $target.siblings().addClass('inactive');
            $target.removeClass('inactive');
        })
            .on('mouseleave', event => {
            const $target = $(event.currentTarget);
            $target.siblings().removeClass('inactive');
        })
            .children(`a[href^="${window.location.pathname}#"]`)
            .on('click', () => {
            this.closeNavbar();
        });
        $(window).on('resize', this.handleResize.bind(this));
    }
    static showBackdrop() {
        this.$backdrop.addClass('show');
        $(document.body).addClass('navbar-open');
        const currentBreakpoint = getCurrentBreakpoint();
        if (currentBreakpoint >= Breakpoint.lg) {
            this.disableBodyScroll($('.js__navbar-collapse-second').get(0));
        }
        requestAnimationFrame(() => {
            enterTransition(this.$backdrop);
        });
    }
    static async hideBackdrop() {
        await leaveTransition(this.$backdrop);
        this.$backdrop.removeClass('show');
        $(document.body).removeClass('navbar-open');
        const currentBreakpoint = getCurrentBreakpoint();
        if (currentBreakpoint >= Breakpoint.lg) {
            this.enableBodyScroll($('.js__navbar-collapse-second').get(0));
        }
    }
    static handleResize() {
        this.setViewportHeightUnit();
        if (this.isDesktopNavbar() &&
            this.$topNavbarCollapse.hasClass('show') &&
            this.$topNavbarCollapse.find('.active').length === 0) {
            this.closeNavbar(false);
        }
    }
    static setViewportHeightUnit() {
        // A workaround for iOS devices viewport height issue which does not exclude top and bottom bars from vh units.
        document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    }
    static isDesktopNavbar() {
        return getCurrentBreakpoint() === Breakpoint.xl;
    }
    static preventDefault(event) {
        event.preventDefault();
    }
    static allowOverlayScroll(event) {
        const target = event.currentTarget;
        if (!target) {
            return;
        }
        const atTop = target.scrollTop === 0 && event.deltaY < 0;
        const atBottom = target.scrollTop + target.clientHeight >= target.scrollHeight && event.deltaY > 0;
        if (atTop || atBottom) {
            event.preventDefault();
        }
        else {
            event.stopPropagation();
        }
    }
    static disableBodyScroll(overlayElement) {
        document.addEventListener('wheel', this.preventDefault, { passive: false });
        overlayElement.addEventListener('wheel', this.allowOverlayScroll);
    }
    static enableBodyScroll(overlayElement) {
        document.removeEventListener('wheel', this.preventDefault);
        overlayElement.removeEventListener('wheel', this.allowOverlayScroll);
    }
}
__decorate([
    throttle(300)
], Navbar, "handleResize", null);
