const hasTransition = (target) => [target, ...target.getElementsByTagName('*')].find(element => parseFloat(window.getComputedStyle(element, null).getPropertyValue('transition-duration') || '0') > 0);
export const enterTransition = ($target, $lastToTransition, transition = true) => {
    return new Promise(resolve => {
        $target.addClass('enter-active enter-to').removeClass('leave-to');
        const handleTransitionEnd = () => {
            $target.removeClass('enter-active');
            resolve($target);
        };
        if (transition && hasTransition($target[0])) {
            $target.on('transitionend', event => {
                if (!$lastToTransition || $lastToTransition.is(event.target)) {
                    handleTransitionEnd();
                    $target.off(event);
                }
            });
        }
        else {
            handleTransitionEnd();
        }
    });
};
export const leaveTransition = ($target, $lastToTransition, transition = true) => {
    return new Promise(resolve => {
        $target.addClass('leave-active leave-to').removeClass('enter-to');
        const handleTransitionEnd = () => {
            $target.removeClass('leave-active');
            resolve($target);
        };
        if (transition && hasTransition($target[0])) {
            $target.on('transitionend', event => {
                if (!$lastToTransition || $lastToTransition.is(event.target)) {
                    handleTransitionEnd();
                    $target.off(event);
                }
            });
        }
        else {
            handleTransitionEnd();
        }
    });
};
